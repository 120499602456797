<template>
    <div class="relative">
        <Header>
            Audit
        </Header>

         <div class="actions p-d-flex p-jc-between p-p-2">
            <AuditFilter :filter="filter" @search="getAudits" @reset="reset" />
        </div>
        
        <DataTable
            :value="items.items"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="items._meta.perPage"
            :totalRecords="items._meta.totalCount"
            ref="audit"
            @page="onPage($event)"
            class="p-datatable-sm">
            <Column headerStyle="width: 3em" field="state">                
                <template #body="slotProps">
                    <States :state="slotProps.data.state" />
                </template>
            </Column>
            <Column headerStyle="width: 12em" field="creator.username" header="Korisnik"></Column>
            <Column field="message" header="Akcija"></Column>
            <Column headerStyle="width: 24em" field="created_at" header="Datum"></Column>
            <Column headerStyle="width: 6em" header="Izmjene">           
                <template #body="slotProps">
                    <Changes :changes="slotProps.data.changes" />
                </template>
            </Column>
            <Column headerStyle="width: 6em" header="Link">           
                <template #body="slotProps">
                    <span v-if="slotProps.data.contractId" @click="link(slotProps.data.contractId)"><i class="pi pi-external-link"></i></span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import AuditFilter from './Filter'
import Header from '@/components/common/Header'
import States from './States'
import router from '@/router'
import Changes from './Changes'
import auditService from '../../services/audit.service'
import contractsService from '../../services/contracts.service'
export default {
    components: {
        Header,
        States,
        Changes,
        AuditFilter
    },
    data() {
        return {
            items: {
                _meta: {
                    perPage: 20
                }
            },
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            },
            loading: true,
        }
    },
    async created() {
        await this.getAudits();
    },    
    methods: {
        async getAudits() {
            this.loading = true
            const { data } = await auditService.get({ params: this.params, filter: this.filter });
            this.items = data
            this.loading = false
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.getAudits()
        },
        async reset() {
            this.filter = {}
            await this.getAudits()
        },
        async link(contractId) {
            const { data } = await this.getContract(contractId)
            if(data.archived === 0 && data.deleted === 0) {                
                router.push({ path: `/contracts/${contractId}` })
            } else if(data.archived === 1 && data.deleted === 0) {               
                router.push({ path: `/archive/${contractId}` })
            } else if(data.archived === 0 && data.deleted === 1) {
                this.$toast.add({severity:'warn', summary: 'Ugovor obrisan', detail:'Ugovor je obrisan', life: 3000});
            } else {
                this.$toast.add({severity:'warn', summary: 'Ugovor nije pronađen', detail:'Nije moguće pronaći ugovor. Obratite se administratoru.', life: 3000});
            }
        },
        async getContract(contractId) {
            return await contractsService.getById(contractId)
        }
    },
}
</script>