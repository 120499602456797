<template>
    <div class="comment p-mb-3" v-bind:class="{ 'mine': isMine }">
        <div class="p-mb-2 comment-user">{{ comment.username }}, {{ comment.created_at }}</div>
        <div class="comment-content p-p-2">
            {{ comment.content }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        comment: {
            type: Object,
            required: true,
        },
        user: {
            type: String,
            required: true,
        }
    },
    computed: {
        isMine: function() {
            return this.comment.username === this.user ? true : false
        }
    }
}
</script>