<template>
    <div class="ui-block">
        <ProgressSpinner style="width:35px;height:35px;" strokeWidth="5" />
        <p v-if="message">{{ message }}</p>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
    .ui-block {
        position: absolute;
        background: rgba(255,255,255,0.85);
        backdrop-filter: blur(1px);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>