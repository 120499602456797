<template>
    <div class="contract--layout p-d-flex p-flex-column">
        <div class="actions p-d-flex p-jc-between p-p-2">
            <Button label="Natrag" icon="pi pi-arrow-left" class="p-button-text p-button-sm" @click="back()" />
        </div>

        <div class="contract--data p-d-flex" v-if="contract">
            <div class="contract--form">                
                <Header>
                    {{ contract.name }} ({{ contract.code }}), {{ contract.date }}
                </Header>
                <Form :contract.sync="contract" :edit.sync="edit" mode="details" ref="form" />
            </div>
            <div class="p-d-flex flex-grow">
                <div class="contract--payments flex-grow">
                    <Payments v-show="!edit" :contract="contract" mode="details" />
                </div>
                <div class="contract--comments">
                    <Comments v-show="!edit" :contract="contract" mode="details" /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import Form from './Form'
import { mapActions } from 'vuex'
import router from '@/router'
import Payments from './payments/Payments'
import Comments from './comments/Comments'
export default {
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            contract: null,
            loading: true,
            edit: false,
        }
    },
    components: {
        Header,
        Form,
        Payments,
        Comments
    },
    async created() {
        this.contract = await this.getContract(this.id)
        this.loading = false
    },
    computed: {
        paymentTitle: function() {
            return `Uplate ${this.contract.payments.length}`
        },
        commentTitle: function() {
            return `Komentari ${this.contract.comments.length}`
        }
    },
    methods: {
        ...mapActions({
            getContract: 'GETCONTRACT',
        }),     
        back() {
            router.push({ path: '/archive' })
        },
    },
}
</script>