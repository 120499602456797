<template>
    <div v-if="changes">
        <i class="pi pi-eye" @click="toggle" aria:haspopup="true" aria-controls="overlay_panel"></i>
        <!-- <Button type="button" icon="pi pi-eye" class="p-button-sm" label="Izmjene" @click="toggle" aria:haspopup="true" aria-controls="overlay_panel" /> -->

        <OverlayPanel ref="op" appendTo="body" id="overlay_panel" :showCloseIcon="true">
            <div v-for="(value, name, index) in changes" :key="index" class="p-my-2 change p-pb-2">
                <label>{{ name }}</label>
                <div class="p-d-flex p-mt-1 p-ai-center">
                    {{ value.old }} <i class="pi pi-arrow-right p-mx-3"></i> <strong>{{ value.new }}</strong>
                </div>
            </div>
        </OverlayPanel>
    </div>
</template>

<script>
export default {
    props: {
        changes: {
            type: Object,
        }
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        }
    }
}
</script>