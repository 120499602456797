import httpClient from "./http.service";

const administrationService = {
    get() {
        return httpClient.get(`administrations?sort=name`)
    },
    create(user) {
        return httpClient.post('administrations', user)
    },
    update(user) {
        return httpClient.put(`administrations/${user.id}`, user)
    },
    delete(userId) {
        return httpClient.delete(`administrations/${userId}`)
    },
    changePassword(passwordChange) {
        return httpClient.post('administration/change-password', passwordChange)
    }
}

export default administrationService;