import { Action, Mutation } from './types'
import authService from '../services/auth.service'
import router from '@/router'

export default {
    state: () => ({
        user: null,
        errors: []
    }),
    actions: {
        async [Action.SETUSER]({ commit }, form) {
            const {success, errors, data} = await authService.login(form)
            if(success) {
                router.push({ name: 'home' })
                commit(Mutation.SETUSER, data)
            } else {
                commit(Mutation.SETERRORS, errors)
            }
            // commit(Mutation.SETUSER, user)
        },
    },
    mutations: {
        [Mutation.SETUSER](state, user) {
            state.user = user
        },
        [Mutation.SETERRORS](state, errors) {
            state.errors = errors
        },
    },
    getters: {
        user: state => {
            return state.user
        },
    }
}