<template>
    <div v-if="state">
        <i :class="icon"></i>
    </div>
</template>

<script>
export default {
    props: {
        state: {
            type: Number,
        }
    },
    computed: {
        icon: function() {
            let state = null
            switch(this.state) {
                case 1:
                    state = "pi pi-plus green"
                    break
                case 2:
                    state = "pi pi-pencil blue"
                    break
                case 3:
                    state = "pi pi-trash red"
                    break
                case 4:
                    state = "pi pi-book purple"
                    break
            }

            return state
        }
    }
}
</script>