import httpClient from "./http.service";

const commentsService = {
    get() {
        return httpClient.get('comments')
    },
    create(comment) {
        return httpClient.post('comments', comment)
    },
    update(comment) {
        return httpClient.put(`comments/${comment.id}`, comment)
    },
    delete(commentId) {
        return httpClient.delete(`comments/${commentId}`)
    }
}

export default commentsService;