import httpClient from "./http.service";

const auditService = {
    get(params) {
        return httpClient.get(`audits?sort=-created_at&page=${params.params.page}`, {
            params: {
                params: params,
            }
        })
    },
    async getUsers() {
        const { status, data } = await httpClient.get('audit/get-users')
        if(status === 200)
            return data
    }
}

export default auditService;